import { DataStorage } from '@studyportals/data-storage';
import globalStore from '..';
import IExperiment from '../../../domain/dto/experiment.interface';

export class ExperimentSystemPlugin {
	private experimentName: string;

	constructor() {
		this.experimentName = 'opt_in_optimisation';
	}

	public initiate(): void {
		DataStorage.init();

		const experimentData: any = DataStorage.retrieve(`Experiment/${this.experimentName}`);
		if (experimentData === '') {
			return;
		}

		// eslint-disable-next-line
		const experiment: IExperiment = (({ name, number }) => ({ name, variation: number }))(experimentData);
		globalStore.actions.setRunningExperiment(experiment);
	}
}
