export default {
	REGISTER: 'Register',
	SIGNIN: 'SignIn',
	OPTIN: 'OptIn',
	GENERAL: 'General',
	BACK_TO_LOGIN_CLICK: 'back_to_login - click',
	FORGOT_PASSWORD_CLICK: 'forgot_password - click',
	GO_TO_LOGIN_CLICK: 'go_to_login - click',
	GO_TO_REGISTER_CLICK: 'go_to_register - click',
	PRIVACY_STATEMENT_CLICK: 'privacy_statement - click',
	TERMS_AND_CONDITIONS_CLICK: 'terms_and_conditions - click',
	SUBMIT_CLICK: 'submit - click',
	TOGGLE_PASSWORD: 'toggle_password',
	CONTINUE_WITH_EMAIL_CLICK: 'continue_with_email - click',
	FACEBOOK_BUTTON_CLICK: 'facebook_button - click',
	GOOGLE_BUTTON_CLICK: 'google_button - click',
	LINKEDIN_BUTTON_CLICK: 'linkedin_button - click',
	OPTIN_IMPRESSION: 'optin - impression',
	INCENTIVE_IMPRESSION: 'incentive - impression',
	AUTH_POPUP_DISMISSED: 'auth_popup - dismissed',
};
