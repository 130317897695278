import globalStore from '@/store/global';
import ITrackingObject from '../../../domain/dto/tracking-object.interface';
import { TrackingEvent } from './tracking-event';
import { FormType, Referrer } from '../../../../interfaces';

export class StructEvent extends TrackingEvent {
	constructor(trackingParams, referrer: Referrer | FormType | null) {
		super(globalStore.getters.portalType(), referrer, globalStore.getters.authProvider());
		this.action = trackingParams.action || '';
		this.label = trackingParams.label || '';
		this.property = trackingParams.property || '';
		this.value = trackingParams.value || null;
	}

	protected get trackingEvent(): ITrackingObject {
		return {
			category: this.category,
			action: this.action,
			label: this.label,
			property: this.property,
			value: this.value,
		};
	}
}
