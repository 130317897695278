import globalStore from '..';
import { InternalEventType } from '../../../domain/enums/internal-event-type';
import globals from '@/utils/globals';

export class FacebookEventListenerPlugin {
	public initiate(): void {
		globals.eventBus.on(InternalEventType.FACEBOOK_INITIALIZED, () => this.addFacebookEventListener());
	}

	private addFacebookEventListener(): void {
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		FB.Event.subscribe('auth.statusChange', (e) => this.handleFacebookStatusChange(e));
	}

	private async handleFacebookStatusChange(e): Promise<void> {
		if (e.status !== 'connected') {
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		await globalStore.actions.startFacebookYoloAuthentication(e.authResponse.accessToken);
	}
}
