/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Actions from '../../dictionary/tracking/actions';
import { ProviderCustomActionType } from '../../domain/enums/provider-custom-action-type';
import { SignInFlow } from '../../infrastructure/clients/studentapi/enums/sign-in-flow';
import { IAuthenticateData } from '../../domain/dto/authenticate-data.interface';
import { OptInEvent } from '../../utils/tracking/events/optin';
import { RegisterEvent } from '../../utils/tracking/events/register';
import { SignInEvent } from '../../utils/tracking/events/signin';
import { StructEvent } from '../../utils/tracking/events/struct';
import { TrackingEvent } from '../../utils/tracking/events/tracking-event';
import globalStore from '.';
import globals from '@/utils/globals';
import { Referrer } from '../../../interfaces';

const actionHelpers = {
	getAdditionalTags(data: IAuthenticateData): string[] {
		const tags: string[] = [];

		if (data.providerCustomAction === ProviderCustomActionType.YOLO) {
			tags.push('Yolo');
		}

		const affiliate = globalStore.getters.affiliate();
		if (affiliate !== null) {
			tags.push(affiliate);
		}

		const trafficSource = globalStore.getters.trafficSource();
		if (trafficSource) {
			tags.push(`TrafficSource:${trafficSource}`);
		}
		return tags;
	},

	trackAuthentication(flow: SignInFlow, referrer: Referrer | null, additionalTags: string[] = []): void {
		let event: TrackingEvent = new SignInEvent(
			globalStore.getters.portalType(),
			referrer,
			globalStore.getters.authProvider(),
			additionalTags,
		);

		if (flow === SignInFlow.REGISTERED) {
			event = new RegisterEvent(
				globalStore.getters.portalType(),
				referrer,
				globalStore.getters.authProvider(),
				additionalTags,
			);
		}

		globals.authenticationServiceTracking.track(event, true, true);
	},

	trackOptIn(option: string): void {
		const event = new OptInEvent(option);

		globals.authenticationServiceTracking.track(event, true, true);
	},

	trackAction(action: string, referrer: Referrer | null): void {
		globals.authenticationServiceTracking.track(
			new StructEvent(
				{
					action: Actions.GENERAL,
					label: action,
				},
				referrer,
			),
			true,
			true,
		);
	},

	trackAuthPopupDismiss(referrer: Referrer | null): void {
		globals.authenticationServiceTracking.track(
			new StructEvent(
				{
					action: Actions.AUTH_POPUP_DISMISSED,
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					label: `${referrer}:${globalStore.getters.portalType}`,
				},
				referrer,
			),
		);
	},
};

export default actionHelpers;
