import {
	ProfileQuestionnairesCpiModalClosed,
	ProfileQuestionnairesReadyEvent,
	QuestionnaireType,
	RenderQuestionnaireCommand,
} from '@studyportals/profile-questionnaires-interfaces';
import { IProfileQuestionnairesApplication } from '@studyportals/profile-questionnaires-interfaces/profile-questionnaires-application.interface.';
import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';
import { SessionCreatedSubscriber } from '@/domain/subscribers/session-created-subscriber';

export class CPIModalPlugin implements ISubscriber<ProfileQuestionnairesReadyEvent> {
	private profileQuestionnaireInstance?: IProfileQuestionnairesApplication;

	constructor(private eventAggregationService: IEventAggregationService) {
		this.eventAggregationService.subscribeTo(ProfileQuestionnairesReadyEvent.EventType, this, true);
		this.eventAggregationService.subscribeTo(ProfileQuestionnairesCpiModalClosed.EventType, this, true);
	}

	public notify(event: ProfileQuestionnairesReadyEvent | ProfileQuestionnairesCpiModalClosed): void {
		if(event.eventType === ProfileQuestionnairesCpiModalClosed.EventType ) {
			this.handleCPIModalClosedEvent();
			return;
		}

		if (event.eventType === ProfileQuestionnairesReadyEvent.EventType) {
			this.profileQuestionnaireInstance = (event as ProfileQuestionnairesReadyEvent).profileQuestionnairesApplication;
		}
	}

	private handleCPIModalClosedEvent(): void {
		new SessionCreatedSubscriber(window['EventAggregationService']);
	}

	public renderCPIModal(): void {
		if (!this.profileQuestionnaireInstance) return;

		const command = new RenderQuestionnaireCommand(QuestionnaireType.STUDY_PREFERENCES, true, true);
		this.profileQuestionnaireInstance.renderQuestionnaire(command);
	}
}
