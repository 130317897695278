import { IStudentSignResult } from '../../infrastructure/clients/studentapi/interfaces/student-sign-result.interface';
import { StudentApiClient } from '../../infrastructure/clients/studentapi/student-api-client';
import { ICaptcha } from '@/domain/dto/captcha.interface';
import { PortalType } from 'interfaces';

export class DirectProvider {
	constructor(private studentAPIClient: StudentApiClient) {}

	public async login(username: string, password: string, payload: object = {}): Promise<IStudentSignResult> {
		const email = username.toLowerCase();

		const idToken = await this.studentAPIClient.login(email, password);
		return this.studentAPIClient.signIn({ cognito: idToken }, payload);
	}

	public async register(
		username: string,
		password: string,
		payload: object,
		captcha: ICaptcha,
	): Promise<IStudentSignResult> {
		const email = username.toLowerCase();

		await this.studentAPIClient.register(email, password, captcha);
		return this.login(email, password, payload);
	}

	public async forgotPassword(username: string, portalType: PortalType): Promise<void> {
		await this.studentAPIClient.forgotPassword(username.toLowerCase(), portalType);
	}

	public async confirmForgotPassword(username: string, password: string, code: string): Promise<void> {
		await this.studentAPIClient.confirmForgotPassword(username, code, password);
	}

	public async changePassword(oldPassword: string, password: string, username: string): Promise<void> {
		const email = username.toLowerCase();
		await this.studentAPIClient.changePassword(email, oldPassword, password);
	}

	public async updateData(accessToken: string, studentData: object): Promise<void> {
		await this.studentAPIClient.updateData(accessToken, studentData);
	}
}
