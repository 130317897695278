import { Referrer } from '../../../interfaces';
import { IIncentiveActions, IIncentiveGetters, IIncentiveMutations } from '../../domain/dto/store.incentive.interfaces';

export class IncentiveActions implements IIncentiveActions {
	private readonly mutations: IIncentiveMutations;
	private readonly getters: IIncentiveGetters;

	public constructor(mutations: IIncentiveMutations, getters: IIncentiveGetters) {
		this.mutations = mutations;
		this.getters = getters;
	}

	public saveReferrer(referrer: Referrer): void {
		this.mutations.setReferrer(referrer);
	}

	public saveReferrerDirect(referrer?: Referrer | undefined): void {
		if (referrer) {
			this.mutations.setReferrerDirect(referrer);
		} else {
			this.mutations.setReferrerDirect(null);
		}
	}
}
