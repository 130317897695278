import { FacebookClient } from '@/infrastructure/clients/facebook/facebook-client';
import { GoogleClient } from '@/infrastructure/clients/google/google-client';
import { LinkedInClient } from '@/infrastructure/clients/linkedin/linkedin-client';
import { SignInFlow } from '@/infrastructure/clients/studentapi/enums/sign-in-flow';
import { IStudentSignResult } from '@/infrastructure/clients/studentapi/interfaces/student-sign-result.interface';
import { StudentApiClient } from '@/infrastructure/clients/studentapi/student-api-client';
import { AuthenticationProviderType } from '../enums/authentication-provider-type';
import { EventType } from '../enums/event-type';
import { ProviderCustomActionType } from '../enums/provider-custom-action-type';
import { DirectProvider } from '../providers/direct-provider';
import { FacebookProvider } from '../providers/facebook-provider';
import { GoogleProvider } from '../providers/google-provider';
import { LinkedInProvider } from '../providers/linkedin-provider';
import { EventBroadcastService } from './event-broadcast-service';
import { DataStorage } from '@studyportals/data-storage';
import IEmailPreference from '../dto/email-preference.interface';
import { InternalEventType } from '../enums/internal-event-type';
import { SessionCreatedSubscriber } from '../subscribers/session-created-subscriber';
import globalStore from '@/store/global';
import globals from '@/utils/globals';
import { PortalType, Referrer } from 'interfaces';

declare const STUDENTAPI_URL: string;
declare const GOOGLE_CLIENT_ID: string;
declare const FACEBOOK_APP_ID: string;
declare const LINKEDIN_REDIRECT_URI: string;
declare const LINKEDIN_CLIENT_ID: string;

export class StudentAuthenticationService {
	private googleProvider: GoogleProvider;
	private facebookProvider: FacebookProvider;
	private linkedinProvider: LinkedInProvider;
	private directProvider: DirectProvider;

	constructor() {
		const studentAPIClient = new StudentApiClient(STUDENTAPI_URL);
		const googleClient = new GoogleClient(GOOGLE_CLIENT_ID);
		const facebookClient = new FacebookClient(FACEBOOK_APP_ID);
		const linkedInClient = new LinkedInClient(LINKEDIN_CLIENT_ID, LINKEDIN_REDIRECT_URI);

		this.googleProvider = new GoogleProvider(googleClient, studentAPIClient);
		this.facebookProvider = new FacebookProvider(facebookClient, studentAPIClient);
		this.linkedinProvider = new LinkedInProvider(linkedInClient, studentAPIClient);
		this.directProvider = new DirectProvider(studentAPIClient);
	}

	public async authenticate(
		provider: AuthenticationProviderType,
		referrer: Referrer | null,
		directAuthType?: ProviderCustomActionType,
	): Promise<IStudentSignResult> {
		const studentSignResult: IStudentSignResult = await this.authenticateWithProvider(
			provider,
			referrer,
			directAuthType,
		);

		this.broadcastEvents(studentSignResult);
		return studentSignResult;
	}

	public async forgotPassword(): Promise<void> {
		await this.directProvider.forgotPassword(
			globalStore.getters.email()?.toLowerCase() || '',
			globalStore.getters.portalType() || PortalType.MASTERS,
		);
	}

	public async confirmForgotPassword(): Promise<void> {
		const username = globalStore.getters.email() || '';
		const code = globalStore.getters.resetToken() || '';
		const password = globalStore.getters.password() || '';

		await this.directProvider.confirmForgotPassword(username, password, code);
	}

	public async changePassword(): Promise<void> {
		const oldPassword = globalStore.getters.oldPassword() || '';
		const password = globalStore.getters.password() || '';
		const session = await globals.sessionService.getSession();

		if (session) {
			const email = session.getUser().email;

			await this.directProvider.changePassword(oldPassword, password, email);
		}
	}

	public async subscribeForEmails(preferences?: IEmailPreference): Promise<void> {
		if (preferences === undefined) {
			await this.directProvider.updateData(globalStore.getters.accessToken() || '', {
				emailing_other: true,
				emailing_favourites: true,
				emailing_scholarships: true,
				emailing_updates: true,
			});
			return;
		}

		await this.directProvider.updateData(globalStore.getters.accessToken() || '', preferences);
	}

	public async showYolo(): Promise<void> {
		try {
			globals.eventBus.on(InternalEventType.YOLO_DISMISSED, () => {
				EventBroadcastService.broadcast(EventType.YOLO_POPUP_DISMISSED, null);
			});

			const googleIdToken = await this.googleProvider.showYolo();
			await globalStore.actions.startGoogleYoloAuthentication(googleIdToken);
		} catch (error) {
			// Ignore error, user closed yolo
		}
	}

	public renderGoogleButton(options: { element: any; template: string }, referrer: Referrer | null): void {
		this.googleProvider.renderGoogleButton(options, referrer);
	}

	public async initialize(): Promise<void> {
		try {
			await this.googleProvider.initialize();
		} catch (e) {
			console.error(e);
		}

		try {
			await this.facebookProvider.initialize();
		} catch (e) {
			console.error(e);
		}
	}

	private broadcastEvents(studentSignResult: IStudentSignResult): void {
		const credentials = studentSignResult.credentials;

		if (studentSignResult.flow === SignInFlow.REGISTERED) {
			EventBroadcastService.broadcast(EventType.STUDENT_REGISTERED, credentials);
		} else {
			EventBroadcastService.broadcast(EventType.EXISTING_STUDENT_LOGGED_IN, credentials);
			// tslint:disable-next-line: no-unused-expression
			new SessionCreatedSubscriber(window['EventAggregationService']);
		}

		EventBroadcastService.broadcast(EventType.STUDENT_LOGGED_IN, credentials);
	}

	private authenticateWithProvider(
		provider: AuthenticationProviderType,
		referrer: Referrer | null,
		providerCustomActionType?: ProviderCustomActionType,
	): Promise<IStudentSignResult> {
		const payload = this.getPayload(referrer);

		switch (provider) {
			case AuthenticationProviderType.FACEBOOK:
				return this.authenticateFacebook(payload, providerCustomActionType!);
			case AuthenticationProviderType.LINKEDIN:
				return this.linkedinProvider.login(payload as object);
			case AuthenticationProviderType.GOOGLE:
				return this.authenticateGoogle(payload);
			case AuthenticationProviderType.DIRECT:
				return this.authenticateDirect(payload, providerCustomActionType!);
			default:
				throw new Error('AuthenticationProviderType not supported');
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private authenticateFacebook(payload: any, providerCustomAction: ProviderCustomActionType) {
		if (providerCustomAction === ProviderCustomActionType.YOLO) {
			return this.facebookProvider.login(payload as object, globalStore.getters.facebookAccessToken());
		} else {
			return this.facebookProvider.login(payload as object);
		}
	}

	private authenticateGoogle(payload: any): Promise<IStudentSignResult> {
		return this.googleProvider.login(payload as object, globalStore.getters.googleIdToken());
	}

	private async authenticateDirect(
		payload: any,
		providerCustomAction: ProviderCustomActionType,
	): Promise<IStudentSignResult> {
		const email = globalStore.getters.email() || '';
		const password = globalStore.getters.password() || '';

		if (providerCustomAction === ProviderCustomActionType.LOGIN) {
			return this.directProvider.login(email, password, payload as object);
		}

		if (providerCustomAction === ProviderCustomActionType.REGISTER) {
			payload = this.addNameToPayload(payload as object);
			const captchaToken = globalStore.getters.captcha();
			return this.directProvider.register(email, password, payload as object, captchaToken!);
		}

		throw new Error('DirectAuthenticationType not supported');
	}

	private getPayload(referrer: Referrer | null): any {
		let payload = {
			referrer,
			registration_portal_type: globalStore.getters.portalType(),
		};

		payload = this.addTrafficSourceToPayload(payload);

		if (undefined !== window['formEntity'] && null !== window['formEntity']['postValues']) {
			payload = Object.assign(window['formEntity']['postValues'], payload);
		}

		return payload;
	}

	private addNameToPayload(payload: any): any {
		const name = globalStore.getters.name() || '';

		const nameSplit = name.split(' ');
		payload.first_name = nameSplit.shift() || '';
		payload.last_name = nameSplit.join(' ');
		payload.name = name;

		return payload;
	}

	private addTrafficSourceToPayload(payload: any): any {
		DataStorage.init();
		const trafficSourceData = DataStorage.retrieve('traffic_source');

		if (trafficSourceData?.['utm_source']) {
			if (trafficSourceData['utm_source'].source) {
				const trafficSource = trafficSourceData['utm_source'].source as string;
				payload['traffic_source'] = trafficSource;
				globalStore.actions.saveTrafficSource(trafficSource);
			}

			if (trafficSourceData['utm_source'].url) {
				payload['traffic_source_url'] = trafficSourceData['utm_source'].url as string;
			}
		}

		return payload;
	}
}
