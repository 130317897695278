import { readonly } from 'vue';
import { IIncentiveState, IIncentiveStore } from '../../domain/dto/store.incentive.interfaces';
import { createIncentiveState } from './state';
import { IncentiveMutations } from './mutations';
import { IncentiveGetters } from './getters';
import { IncentiveActions } from './actions';

const createIncentiveStore = (): IIncentiveStore => {
	const state = createIncentiveState();
	const mutations = new IncentiveMutations(state);
	const getters = new IncentiveGetters(state);
	const actions = new IncentiveActions(mutations, getters);

	return {
		state: readonly(state) as IIncentiveState,
		mutations,
		getters,
		actions,
	};
};

export { createIncentiveStore };
