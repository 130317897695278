import { CognitoApiClient } from '@/infrastructure/clients/studentapi/cognito-api-client';
import { NoEmailError } from '../errors/no-email-error';
import { SignInFlow } from './enums/sign-in-flow';
import { UnknownStudentApiError } from './errors/unknown-student-api-error';
import { ValidationStudentApiError } from './errors/validation-student-api-error';
import { IStudentSignResult } from './interfaces/student-sign-result.interface';
import { StudentAPIErrorCode } from '@/domain/enums/student-api-error-code';
import { StudentAPIErrorReponse } from '@/domain/dto/student-api-error-response.interface';
import { ICaptcha } from '@/domain/dto/captcha.interface';
import { PortalType } from 'interfaces';

declare const AWS_REGION: string;
declare const COGNITO_CLIENT_ID: string;

export class StudentApiClient {
	private studentAPIBaseUrl: string;
	private _cognitoAPIClient: Promise<CognitoApiClient> | null = null;

	constructor(studentAPIBaseUrl: string) {
		this.studentAPIBaseUrl = studentAPIBaseUrl;
	}

	private get cognitoAPIClient(): Promise<CognitoApiClient> {
		if (this._cognitoAPIClient === null) {
			const client = import('./cognito-api-client').then((c) => new c.CognitoApiClient());

			this._cognitoAPIClient = client;
		}

		return Promise.resolve(this._cognitoAPIClient);
	}

	public async signIn(credentials: any, payload: any): Promise<IStudentSignResult> {
		try {
			const result = await this.doRequest('/signIn', 'POST', { credentials, payload });
			return this.constructSignInResponse(result.body, result.status);
		} catch (e) {
			const body = e as StudentAPIErrorReponse;
			if (body.errors) {
				throw new ValidationStudentApiError(body.errors);
			}

			throw new UnknownStudentApiError(body);
		}
	}

	public async login(username: string, password: string): Promise<string> {
		const client = await this.cognitoAPIClient;
		const result = await client.login(username, password);

		return result.IdToken!;
	}

	public async register(username: string, password: string, captcha: ICaptcha): Promise<void> {
		const client = await this.cognitoAPIClient;
		return client.register(username, password, captcha);
	}

	public async forgotPassword(username: string, portalType: PortalType): Promise<void> {
		const client = await this.cognitoAPIClient;
		await client.forgotPassword(username, portalType);
	}

	public async changePassword(
		username: string,
		previousPassword: string,
		proposedPassword: string,
	): Promise<object | void> {
		const client = await this.cognitoAPIClient;
		await client.changePassword(username, previousPassword, proposedPassword);
	}

	public async confirmForgotPassword(username: string, confirmationCode: string, password: string): Promise<void> {
		const client = await this.cognitoAPIClient;
		await client.confirmForgotPassword(username, confirmationCode, password);
	}

	public async updateData(accessToken: string, studentData: object): Promise<void> {
		try {
			await this.doAuthenticatedRequest('/data', 'PATCH', studentData, accessToken);
		} catch (e) {
			const body = e as any;
			if (body.code === StudentAPIErrorCode.BAD_INPUT) {
				throw new ValidationStudentApiError(body.errors);
			}

			throw new UnknownStudentApiError(body);
		}
	}

	protected async doAuthenticatedRequest(
		path: string,
		method: string,
		body: object,
		accessToken: string,
	): Promise<{ body: any; status: number }> {
		return this.doRequest(path, method, body, { Authorization: accessToken });
	}

	protected async doRequest(
		path: string,
		method: string,
		body: object,
		headers: object = {},
	): Promise<{ body: any; status: number }> {
		const result = await fetch(`${this.studentAPIBaseUrl}${path}`, {
			method,
			body: JSON.stringify(body),
			headers: headers as Headers,
		});

		const responseBody = await result.json();

		if (!result.ok) {
			throw responseBody;
		}

		return { body: responseBody, status: result.status };
	}

	private constructSignInResponse(body: any, statusCode: number): IStudentSignResult {
		return {
			credentials: {
				accessToken: body.accessToken,
				refreshToken: body.refreshToken,
			},
			flow: this.getSignInFlowByResponseCode(statusCode),
		};
	}

	private getSignInFlowByResponseCode(statusCode: number): SignInFlow {
		switch (statusCode) {
			case 201:
				return SignInFlow.REGISTERED;
			case 225:
				return SignInFlow.CONVERTED;
			default:
				return SignInFlow.LOGIN;
		}
	}
}
