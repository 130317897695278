import { FormType } from '../../../interfaces/enums/form-type';
import globalStore from '@/store/global';
import { computed } from 'vue';

export default class FormApp {
	public formTypes = computed((): typeof FormType => {
		return FormType;
	});

	public form: FormType;

	constructor(form: FormType) {
		this.form = form;
	}

	mounted(): void {
		this.trackImpression();
	}

	private trackImpression(): void {
		globalStore.actions.trackImpression(this.form);
	}
}
