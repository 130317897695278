import ITrackingObject from '../../../domain/dto/tracking-object.interface';
import Actions from '../../../dictionary/tracking/actions';
import { TrackingEvent } from './tracking-event';

export class OptInEvent extends TrackingEvent {
	protected action: string = Actions.OPTIN;

	constructor(label) {
		super(null, null, null);
		this.label = label;
	}

	protected get trackingEvent(): ITrackingObject {
		return {
			category: this.category,
			action: this.action,
			label: this.label,
		};
	}
}
