import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmForgotPassword = _resolveComponent("ConfirmForgotPassword")!
  const _component_ChangePasswordForm = _resolveComponent("ChangePasswordForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.form === _ctx.formTypes.CONFIRM_FORGOT_PASSWORD)
      ? (_openBlock(), _createBlock(_component_ConfirmForgotPassword, { key: 0 }))
      : _createCommentVNode("v-if", true),
    (_ctx.form === _ctx.formTypes.CHANGE_PASSWORD)
      ? (_openBlock(), _createBlock(_component_ChangePasswordForm, { key: 1 }))
      : _createCommentVNode("v-if", true)
  ]))
}