import { SessionCreatedEvent } from '@studyportals/student-interfaces';
import { EventType } from '../enums/event-type';
import { EventBroadcastService } from '../services/event-broadcast-service';
import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class SessionCreatedSubscriber implements ISubscriber<SessionCreatedEvent> {
	constructor(eventAggregationService?: IEventAggregationService) {
		if (eventAggregationService) {
			eventAggregationService.subscribeTo(SessionCreatedEvent.EventType, this, true);
		} else {
			this.notify();
		}
	}

	public notify(): void {
		EventBroadcastService.broadcast(EventType.AUTHENTICATION_COMPLETED, {});
	}
}
