import { reactive } from 'vue';
import { IIncentiveState } from '../../domain/dto/store.incentive.interfaces';

const createIncentiveState = (): IIncentiveState => {
	return reactive({
		referrer: null,
		referrerDirect: null,
	});
};

export { createIncentiveState };
