import globalStore from '@/store/global';
import { ISessionService, ITokenBasedSession } from '@studyportals/student-interfaces';
import { StudentApiClient } from '../../infrastructure/clients/studentapi/student-api-client';
import { SessionNotFoundException } from '../errors/session-not-found-exception';
import { Referrer } from '../../../interfaces/enums/referrer';
import { CaptchaType } from '@/domain/enums/captcha-type';

declare const STUDENTAPI_URL: string;

export class AccountService {
	private studentApiClient: StudentApiClient;

	constructor(private sessionService: ISessionService) {
		this.studentApiClient = new StudentApiClient(STUDENTAPI_URL);
	}

	public async confirmForgotPassword(confirmationCode: string, password: string): Promise<void> {
		const session = await this.getSession();
		const user = session.getUser();

		await session.getAccessToken();
		await this.studentApiClient.confirmForgotPassword(user.email, confirmationCode, password);
	}

	public async deleteAccount(): Promise<void> {
		throw new Error('Not implemented yet');
	}

	public async getSession(): Promise<ITokenBasedSession> {
		const session = (await this.sessionService.getSession()) as ITokenBasedSession;
		if (session === null) {
			throw new SessionNotFoundException();
		}

		return session;
	}

	public async register(username: string, password: string, referrer: Referrer, name?: string): Promise<void> {
		if (name) {
			globalStore.actions.saveName(name);
		}

		globalStore.actions.saveEmail(username);
		globalStore.actions.savePassword(password);
		globalStore.actions.setPasswordValid(true);

		await globalStore.actions.register(referrer, false);
	}

	public async login(username: string, password: string, referrer: Referrer): Promise<void> {
		globalStore.actions.saveEmail(username);
		globalStore.actions.savePassword(password);

		await globalStore.actions.login(referrer);
	}

	public bypassCaptcha(token: string): void {
		globalStore.actions.saveCaptcha({
			type: CaptchaType.BYPASS,
			token,
		});
	}
}
