/* eslint-disable @typescript-eslint/naming-convention */
import { ActionState } from '../../../interfaces/enums/action-state';
import { LayoutType } from '../../../interfaces/enums/layout-type';
import { PopUpType } from '@/domain/enums/pop-up-type';
import { PortalType } from '../../../interfaces/enums/portal-type';
import { Referrer } from '../../../interfaces/enums/referrer';
import globalStore from '@/store/global';
import globals from '@/utils/globals';
import { computed } from 'vue';
import { IncentiveBase } from './incentive-base.class';

// tslint:disable-next-line: class-name
// eslint-disable-next-line @typescript-eslint/naming-convention
// tslint:disable-next-line: class-name
export default class app extends IncentiveBase {
	public layoutTypes = computed((): typeof LayoutType => {
		return LayoutType;
	});

	public popUpType = computed((): typeof PopUpType => {
		return PopUpType;
	});

	public referrer: Referrer;

	public portalType: PortalType;

	public layout: LayoutType;

	public tabToShow: ActionState;

	public mountInPopUp: boolean;

	public appIdentifier: string;

	constructor(
		layout: LayoutType,
		referrer: Referrer,
		tabToShow: ActionState,
		portalType: PortalType,
		isPopup: boolean,
		appIdentifier: string,
	) {
		super();

		this.layout = layout;
		this.referrer = referrer;
		this.tabToShow = tabToShow;
		this.portalType = portalType;
		this.mountInPopUp = isPopup;
		this.appIdentifier = appIdentifier;
	}

	mounted(): void {
		if (this.mountInPopUp) {
			document.addEventListener('auth_layout_mounted', (event: any) => {
				if (event.detail.layout !== this.layout || event.detail.appIdentifier !== this.appIdentifier) return;
				globals.eventBus.emit('openPopup', {
					identifier: PopUpType.AUTH_POP_UP,
					referrer: this.referrer,
					appIdentifier: this.appIdentifier,
				});
			});
		}

		globalStore.actions.setActionState(this.tabToShow);
		globalStore.actions.setLayoutType(this.layout);
		globalStore.actions.savePortalType(this.portalType);
		this.incentiveStore.actions.saveReferrer(this.referrer);

		this.trackImpression();
		this.listenForTabSwitch();
		this.mountLayout();
	}

	private mountLayout(): void {
		if (this.layout === LayoutType.MAIN || this.layout === LayoutType.MAIN_YOLO) {
			this.fireLayoutMountedEvent(LayoutType.MAIN);
			this.fireLayoutMountedEvent(LayoutType.MAIN_YOLO);
		} else {
			this.fireLayoutMountedEvent(this.layout);
		}
	}

	private fireLayoutMountedEvent(layout: LayoutType): void {
		document.dispatchEvent(
			new CustomEvent('auth_layout_mounted', {
				detail: {
					layout,
					appIdentifier: this.appIdentifier,
				},
			}),
		);
	}

	private trackImpression(): void {
		globalStore.actions.trackImpression(this.referrer);
	}

	private listenForTabSwitch(): void {
		document.addEventListener('RenderAuthenticationService', (event: any) => {
			if (
				event.detail.referrer === this.referrer &&
				// eslint-disable-next-line valid-typeof
				typeof event.detail.tabToShow !== undefined
			) {
				globalStore.actions.setActionState(event.detail.tabToShow);
			}
		});
	}
}
