import { IGlobalMutations } from '@/domain/dto/store.global.interfaces';
import { ActionState } from '../../../interfaces/enums/action-state';
import { PortalType } from '../../../interfaces/enums/portal-type';
import { AuthenticationProviderType } from '../../domain/enums/authentication-provider-type';
import IExperiment from '../../domain/dto/experiment.interface';
import { LayoutType } from '../../../interfaces/enums/layout-type';

import { ErrorMessageType } from '@/domain/enums/error-message-type';
import state from './state';
import { ICaptcha } from '@/domain/dto/captcha.interface';
import { Referrer } from '../../../interfaces';

const mutations: IGlobalMutations = {
	setActionState: (actionState: ActionState) => {
		state.actionState = actionState;
	},
	setLayout: (layout: LayoutType) => {
		state.layout = layout;
	},
	setName: (name: string) => {
		state.name = name;
	},
	setEmail: (email: string) => {
		state.email = email;
	},
	setPassword: (password: string) => {
		state.password = password;
	},
	setOldPassword: (password: string) => {
		state.oldPassword = password;
	},
	setConfirmPassword: (password: string) => {
		state.confirmPassword = password;
	},
	setPortalType: (portalType: PortalType) => {
		state.portalType = portalType;
	},
	setAuthProvider: (authProvider: AuthenticationProviderType) => {
		state.authProvider = authProvider;
	},
	setAffiliate: (affiliate: string) => {
		state.affiliate = affiliate;
	},
	setTrafficSource: (source: string) => {
		state.trafficSource = source;
	},
	setPending: (isPending: boolean, authProvider: AuthenticationProviderType) => {
		state.pending = {
			isPending,
			authProvider,
		};
	},
	setErrorMessage: (message: string | null, type: ErrorMessageType | AuthenticationProviderType | null) => {
		state.errorMessage = {
			message,
			type,
		};
	},
	setShowSuccessMessage: (shouldShow: boolean) => {
		state.showSuccessMessage = shouldShow;
	},
	directAuthTriggered: () => {
		state.directAuthTriggered = true;
	},
	setPasswordValid: (valid: boolean) => {
		state.isValidPassword = valid;
	},
	setAccessToken: (accessToken: string) => {
		state.accessToken = accessToken;
	},
	resetPasswordEmailSend: (send: boolean) => {
		state.resetPasswordEmailSend = send;
	},

	setLoggedInState: (loggedInState: boolean) => {
		state.userLoggedIn = loggedInState;
	},
	setGoogleIdToken: (googleIdToken: string) => {
		state.googleIdToken = googleIdToken;
	},
	setFacebookAccessToken: (facebookAccessToken: string | null) => {
		state.facebookAccessToken = facebookAccessToken;
	},
	setCaptcha: (captcha: ICaptcha | null) => {
		state.captcha = captcha;
	},
	setRunningExperiment: (experiment: IExperiment) => {
		state.runningExperiment = experiment;
	},
	setResetToken: (token: string) => {
		state.resetToken = token;
	},
	setReferrerGoogleAuth: (referrer: Referrer | null): void => {
		state.referrerGoogleAuth = referrer;
	},
	setReferrerFacebookAuth: (referrer: Referrer | null): void => {
		state.referrerFacebookAuth = referrer;
	},
};

export default mutations;
