import { SPTracker } from './sp-tracker';
import Categories from '../../dictionary/tracking/categories';
import Actions from '../../dictionary/tracking/actions';
import globalStore from '@/store/global';
import { Referrer } from '../../../interfaces';

declare const STAGE: string;

export default class Tracking {
	private spTracker: SPTracker;

	constructor() {
		this.spTracker = new SPTracker({
			environment: STAGE,
			team: 'xm',
			product: 'AuthService',
			category: Categories.PROFILE,
		});
	}

	public track(event, snowPlow = true, spAnalytics = false): void {
		if (snowPlow) {
			this.snowplow(event);
		}

		if (spAnalytics) {
			this.spAnalytics(event);
		}
	}

	public trackError(error, referrer: Referrer | null): void {
		const errorType = error.constructor.name;

		if (this.isUnknownError(errorType as string)) {
			this.rollbar(error);
		}
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		this.spTracker.track({
			action: Actions.GENERAL,
			label: errorType,
			tags: [globalStore.getters.authProvider(), globalStore.getters.portalType(), referrer],
		});
	}

	private isUnknownError(errorType: string): boolean {
		const unknownErrorTypes: string[] = [
			'UnknownStudentApiSignInError',
			'UnknownLinkedInError',
			'UnknownGoogleError',
			'UnknownFacebookError',
		];

		return unknownErrorTypes.indexOf(errorType) > -1;
	}

	private rollbar(error): void | null {
		if (typeof window['rollbar'] === 'undefined') {
			return null;
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		window['rollbar'].error(error);
	}

	private snowplow(event): void | null {
		if (typeof window['snowplow'] === 'undefined') {
			return null;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
		const trackingEvent = event.get('redshift');

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		window['snowplow']('trackStructEvent', {
			category: trackingEvent.category,
			action: trackingEvent.action,
			label: trackingEvent.label || '',
			property: trackingEvent.property || '',
			value: trackingEvent.value || 0,
		});
	}

	private spAnalytics(event): void {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		this.spTracker.track(
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			event.get('spanalytics'),
		);
	}
}
