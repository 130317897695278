import { ISessionService, SessionDestroyedEvent } from '@studyportals/student-interfaces';

import { SessionNotFoundException } from '../errors/session-not-found-exception';
import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class LogOutService implements ISubscriber<void> {
	constructor(
		private sessionService: ISessionService,
		private eventAggregationService: IEventAggregationService,
	) {
		this.eventAggregationService.subscribeTo(SessionDestroyedEvent.EventType, this);
	}

	public async logout(): Promise<void> {
		const session = await this.sessionService.getSession();

		if (session === null) {
			throw new SessionNotFoundException();
		}

		await session.destroy();
	}

	public notify(): void {
		const logoutButton = document.getElementById('signOutLink');
		if (this.isValidSignOutLink(logoutButton)) {
			return this.setLocation(logoutButton!.dataset.href!);
		}

		this.setLocation(window.location.href);
	}

	private setLocation(url: string): void {
		window.location.href = url;
		window.location.reload();
	}

	private isValidSignOutLink(logoutButton: HTMLElement | null): boolean {
		return logoutButton instanceof Element && logoutButton.dataset.href !== undefined;
	}
}
