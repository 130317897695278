import { FacebookClient } from '../../infrastructure/clients/facebook/facebook-client';
import { IStudentSignResult } from '../../infrastructure/clients/studentapi/interfaces/student-sign-result.interface';
import { StudentApiClient } from '../../infrastructure/clients/studentapi/student-api-client';

export class FacebookProvider {
	constructor(
		private facebookClient: FacebookClient,
		private studentAPIClient: StudentApiClient,
	) {}

	public async initialize(): Promise<void> {
		return this.facebookClient.initializeSDK();
	}

	public async login(payload: object = {}, accessToken: string | null = null): Promise<IStudentSignResult> {
		if (accessToken === null) {
			const result = await this.facebookClient.login();
			accessToken = result.code;
		}

		return await this.studentAPIClient.signIn({ facebook: accessToken }, payload);
	}
}
