import { ActionState, Referrer } from '../enums';

export class RenderIncentiveCommand extends CustomEvent<RenderAuthenticationServiceRequest> {
	constructor(referrer: Referrer, tabToShow?: ActionState, referrerDirect?: Referrer) {
		super('RenderAuthenticationService', {
			detail: {
				referrer,
				tabToShow,
				referrerDirect,
			},
		});
	}
}

interface RenderAuthenticationServiceRequest {
	referrer: Referrer;
	tabToShow?: ActionState;
	referrerDirect?: Referrer;
}
