import { PopUpType } from '@/domain/enums/pop-up-type';
import globalStore from '@/store/global';
import globals from '@/utils/globals';
import { StructEvent } from '@/utils/tracking/events/struct';
import Actions from '@/dictionary/tracking/actions';
import { IncentiveBase } from '../incentive-base.class';

export default class OptIn extends IncentiveBase {
	mounted(): void {
		this.setOpenPopUpListener();
	}

	public async accept(): Promise<void> {
		await globalStore.actions.acceptOptIn();
	}

	public notNow(): void {
		globalStore.actions.denyOptIn();
	}

	public trackImpression(): void {
		const referrer = this.incentiveStore.getters.referrer();
		globals.authenticationServiceTracking.track(
			new StructEvent(
				{
					action: Actions.OPTIN_IMPRESSION,
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					label: `${referrer}:${globalStore.getters.portalType()}`,
				},
				referrer,
			),
			true,
			true,
		);
	}
	private setOpenPopUpListener(): void {
		globals.eventBus.on('openPopup', (params: any) => {
			this.incentiveStore.mutations.setReferrer(params.referrer);

			if (params.identifier === PopUpType.OPT_INT_POP_UP) {
				this.trackImpression();
			}
		});
	}
}
