import Main from '@/main';
import { IAuthenticationServiceApplication, RenderIncentiveCommand } from 'interfaces';
import { RenderFormCommand } from '../../interfaces/commands/render-form-command';
import { AccountService } from '../domain/services/account-service';
import { LogOutService } from '../domain/services/log-out-service';
import { Referrer } from '../../interfaces/enums/referrer';

export class AuthenticationServiceApplication implements IAuthenticationServiceApplication {
	constructor(
		private logOutService: LogOutService,
		private accountService: AccountService,
	) {}

	public async logout(): Promise<void> {
		return this.logOutService.logout();
	}

	public async confirmForgotPassword(confirmationCode: string, password: string): Promise<void> {
		await this.accountService.confirmForgotPassword(confirmationCode, password);
		setTimeout(() => void this.logout(), 3000);
	}

	public async deleteAccount(): Promise<void> {
		return this.accountService.deleteAccount();
	}

	public async register(username: string, password: string, name: string, referrer?: Referrer): Promise<void> {
		return this.accountService.register(username, password, referrer ?? Referrer.OTHER, name);
	}

	public async login(username: string, password: string): Promise<void> {
		return this.accountService.login(username, password, Referrer.OTHER);
	}

	public bypassCaptcha(token: string): void {
		this.accountService.bypassCaptcha(token);
	}

	public renderIncentive(command: RenderIncentiveCommand): void {
		Main.handleRenderIncentiveCommand(command);
	}

	public renderForm(command: RenderFormCommand): void {
		Main.handleRenderFormCommand(command);
	}
}
