import { defineComponent, onMounted } from 'vue';
import PopUp from './PopUp.class';
import { setupIncentive } from '../incentive-base';

export default defineComponent({
	props: {
		identifier: String,
		referrer: String,
		type: String,
		appIdentifier: String,
	},
	setup: (props) => {
		const component = setupIncentive(new PopUp(props.referrer, props.identifier, props.type, props.appIdentifier));
		component.created();
		onMounted(component.mounted.bind(component));

		return {
			state: component.state,
			popupContent: component.popupContent,
		};
	},
});
