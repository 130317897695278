import { EventType } from '../';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class AuthenticationCompletedEvent implements IEvent {
	public static EventType: string = EventType.AUTHENTICATION_COMPLETED;

	public readonly timestamp: Date;
	public readonly eventType = AuthenticationCompletedEvent.EventType;

	constructor() {
		this.timestamp = new Date();
	}
}
