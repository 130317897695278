export enum StudentAPIErrorCode {
	EMAIL_EXISTS = 'EmailExists',
	NO_EMAIL = 'NoEmailFound',
	BAD_INPUT = 'BadInput',
	USER_NOT_FOUND = 'UserNotFound',
	COGNITO_PASSWORD_INCORRECT = 'CognitoPasswordIncorrect',
	USERNAME_EXISTS = 'UsernameExistsException',
	USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
	CONFLICTING_IDENTITY = 'ConflictingIdentity',
}
