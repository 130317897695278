import { Referrer } from '../../../interfaces';
import { IIncentiveGetters, IIncentiveState } from '../../domain/dto/store.incentive.interfaces';

export class IncentiveGetters implements IIncentiveGetters {
	private readonly state: IIncentiveState;

	public constructor(state: IIncentiveState) {
		this.state = state;
	}

	public referrer(): Referrer | null {
		return this.state.referrer;
	}

	public referrerDirect(): Referrer | null {
		return this.state.referrerDirect;
	}
}
