import { LinkedInUserCancelledAuthorize } from './errors/linkedin-user-cancelled-authorize';
import { LinkedInUserCancelledLogin } from './errors/linkedin-user-cancelled-login';
import { UnknownLinkedInError } from './errors/unknown-linkedin-error';
import { LinkedInEventType } from './linkedin-event.enum';
import { LinkedInPopupClosedError } from './errors/linkedin-popup-closed-error';

export class LinkedInClient {
	private linkedInBaseUrl = 'https://www.linkedin.com/oauth/v2/authorization';
	private linkedInWindow: Window | null = null;
	private linkedInCompleted = false;
	private window: Window;

	constructor(
		private linkedInClientId: string,
		private linkedInRedirectUri: string,
	) {
		this.window = window;
	}

	public async login(): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			this.openPopup();
			this.attachCloseHandler(reject);

			await this.listenForLinkedInPopupReady();
			this.sendAuthenticationServiceReadyEvent();

			try {
				resolve(await this.retrieveTokenFromLinkedInUserLoggedInEvent());
			} catch (error) {
				reject(error);
			}
		});
	}

	private constructUrl(): string {
		// eslint-disable-next-line max-len
		return `${this.linkedInBaseUrl}?client_id=${this.linkedInClientId}&redirect_uri=${this.linkedInRedirectUri}&scope=profile,email,openid&response_type=code`;
	}

	private openPopup(): void {
		const url = this.constructUrl();
		this.linkedInWindow = window.open(url, 'MsgWindow', 'width=400,height=650');
	}

	private attachCloseHandler(reject: (error: Error) => void): void {
		const interval = setInterval(() => {
			if (this.linkedInWindow && this.linkedInWindow.closed && !this.linkedInCompleted) {
				clearInterval(interval);
				reject(new LinkedInPopupClosedError());
			}
		}, 100);
	}

	private listenForLinkedInPopupReady(): Promise<void> {
		return new Promise<void>((resolve) => {
			const eventListener = this.window.addEventListener('message', (e) => {
				if (e.data.type === LinkedInEventType.LINKEDIN_POPUP_READY) {
					this.removeListener(eventListener);
					resolve();
				}
			});
		});
	}

	private retrieveTokenFromLinkedInUserLoggedInEvent(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const eventListener = this.window.addEventListener('message', (e) => {
				if (e.data.type === LinkedInEventType.LINKEDIN_USER_LOGGED_IN) {
					this.removeListener(eventListener);
					this.linkedInCompleted = true;
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					resolve(e.data.token);
				}

				if (e.data.type === LinkedInEventType.LINKEDIN_ERROR_OCCURRED) {
					this.removeListener(eventListener);
					this.linkedInCompleted = true;
					reject(this.constructErrorFromEventData(e.data));
				}
			});
		});
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private constructErrorFromEventData(data) {
		switch (data.error) {
			case 'user_cancelled_login':
				return new LinkedInUserCancelledLogin();
			case 'user_cancelled_authorize':
				return new LinkedInUserCancelledAuthorize();
			default:
				return new UnknownLinkedInError(data.error);
		}
	}

	private sendAuthenticationServiceReadyEvent(): void {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		this.linkedInWindow!.postMessage({ type: LinkedInEventType.AUTHENTICATION_SERVICE_READY }, '*');
	}

	private removeListener(listener: any): void {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		this.window.removeEventListener('message', listener);
	}
}
