import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Main = _resolveComponent("Main")!
  const _component_ProfileQuestionnaires = _resolveComponent("ProfileQuestionnaires")!
  const _component_ExclusiveAccess = _resolveComponent("ExclusiveAccess")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Reviews = _resolveComponent("Reviews")!
  const _component_UnibuddyIncentive = _resolveComponent("UnibuddyIncentive")!
  const _component_ATPIncentive = _resolveComponent("ATPIncentive")!
  const _component_PopUp = _resolveComponent("PopUp")!
  const _component_ConfirmForgotPassword = _resolveComponent("ConfirmForgotPassword")!
  const _component_ChangePasswordForm = _resolveComponent("ChangePasswordForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.mountInPopUp)
      ? (_openBlock(), _createBlock(_component_PopUp, {
          key: 0,
          type: 'Complex',
          identifier: _ctx.popUpType.AUTH_POP_UP,
          referrer: _ctx.referrer,
          appIdentifier: _ctx.appIdentifier
        }, {
          default: _withCtx(() => [
            (_ctx.layout === _ctx.layoutTypes.MAIN || _ctx.layout === _ctx.layoutTypes.MAIN_YOLO)
              ? (_openBlock(), _createBlock(_component_Main, { key: 0 }))
              : _createCommentVNode("v-if", true),
            (_ctx.layout === _ctx.layoutTypes.PROFILE_QUESTIONNAIRES)
              ? (_openBlock(), _createBlock(_component_ProfileQuestionnaires, { key: 1 }))
              : _createCommentVNode("v-if", true),
            (_ctx.layout === _ctx.layoutTypes.EXCLUSIVE_ACCESS)
              ? (_openBlock(), _createBlock(_component_ExclusiveAccess, { key: 2 }))
              : _createCommentVNode("v-if", true),
            (_ctx.layout === _ctx.layoutTypes.HEADER)
              ? (_openBlock(), _createBlock(_component_Header, { key: 3 }))
              : _createCommentVNode("v-if", true),
            (_ctx.layout === _ctx.layoutTypes.REVIEWS)
              ? (_openBlock(), _createBlock(_component_Reviews, { key: 4 }))
              : _createCommentVNode("v-if", true),
            (_ctx.layout === _ctx.layoutTypes.OPENID)
              ? (_openBlock(), _createBlock(_component_UnibuddyIncentive, {
                  key: 5,
                  currentState: _ctx.flowState
                }, null, 8 /* PROPS */, ["currentState"]))
              : _createCommentVNode("v-if", true),
            (_ctx.layout === _ctx.layoutTypes.ATP_INCENTIVE)
              ? (_openBlock(), _createBlock(_component_ATPIncentive, { key: 6 }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["identifier", "referrer", "appIdentifier"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.layout === _ctx.layoutTypes.MAIN || _ctx.layout === _ctx.layoutTypes.MAIN_YOLO)
            ? (_openBlock(), _createBlock(_component_Main, { key: 0 }))
            : _createCommentVNode("v-if", true),
          (_ctx.layout === _ctx.layoutTypes.PROFILE_QUESTIONNAIRES)
            ? (_openBlock(), _createBlock(_component_ProfileQuestionnaires, { key: 1 }))
            : _createCommentVNode("v-if", true),
          (_ctx.layout === _ctx.layoutTypes.EXCLUSIVE_ACCESS)
            ? (_openBlock(), _createBlock(_component_ExclusiveAccess, { key: 2 }))
            : _createCommentVNode("v-if", true),
          (_ctx.layout === _ctx.layoutTypes.HEADER)
            ? (_openBlock(), _createBlock(_component_Header, { key: 3 }))
            : _createCommentVNode("v-if", true),
          (_ctx.layout === _ctx.layoutTypes.REVIEWS)
            ? (_openBlock(), _createBlock(_component_Reviews, { key: 4 }))
            : _createCommentVNode("v-if", true),
          (_ctx.layout === _ctx.layoutTypes.OPENID)
            ? (_openBlock(), _createBlock(_component_UnibuddyIncentive, {
                key: 5,
                currentState: _ctx.flowState
              }, null, 8 /* PROPS */, ["currentState"]))
            : _createCommentVNode("v-if", true),
          (_ctx.layout === _ctx.layoutTypes.CONFIRM_FORGOT_PASSWORD)
            ? (_openBlock(), _createBlock(_component_ConfirmForgotPassword, { key: 6 }))
            : _createCommentVNode("v-if", true),
          (_ctx.layout === _ctx.layoutTypes.CHANGE_PASSWORD)
            ? (_openBlock(), _createBlock(_component_ChangePasswordForm, { key: 7 }))
            : _createCommentVNode("v-if", true)
        ]))
  ]))
}