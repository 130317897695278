import { IStudentSignResult } from '../../infrastructure/clients/studentapi/interfaces/student-sign-result.interface';
import { StudentApiClient } from '../../infrastructure/clients/studentapi/student-api-client';
import { LinkedInClient } from '../../infrastructure/clients/linkedin/linkedin-client';

export class LinkedInProvider {
	constructor(
		private linkedInClient: LinkedInClient,
		private studentAPIClient: StudentApiClient,
	) {}

	public async login(payload: object = {}): Promise<IStudentSignResult> {
		const authCode = await this.linkedInClient.login();
		return await this.studentAPIClient.signIn({ linkedin: authCode }, payload);
	}
}
