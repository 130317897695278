import { InternalEventType } from '@/domain/enums/internal-event-type';
import { IFacebookSignInResult } from './facebook-signin-result.interface';
import { UnknownFacebookError } from './errors/unknown-facebook-error';
import importModule from '@/utils/dynamic-import-polyfill';
import globals from '@/utils/globals';

export class FacebookClient {
	private retrievalFacebookSDK: Promise<any> | null = null;

	constructor(private appId: string) {}

	public async initializeSDK(): Promise<void> {
		if (!this.retrievalFacebookSDK) {
			await importModule('//connect.facebook.net/en_US/sdk.js', null);

			this.retrievalFacebookSDK = Promise.resolve(
				FB.init({
					appId: this.appId,
					autoLogAppEvents: true,
					xfbml: true,
					version: 'v7.0',
				}),
			);

			globals.eventBus.emit(InternalEventType.FACEBOOK_INITIALIZED);
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return this.retrievalFacebookSDK;
	}

	public async login(): Promise<IFacebookSignInResult> {
		await this.initializeSDK();
		const accessToken = await this.getAuthCode();

		return {
			code: accessToken,
		};
	}

	private getAuthCode(): Promise<string> {
		return new Promise((resolve, reject) => {
			FB.login(
				(response) => {
					if (response.status === 'connected') {
						resolve(response.authResponse.accessToken as string);
						return;
					}
					reject(new UnknownFacebookError(response));
				},
				{ scope: 'email', return_scopes: true },
			);
		});
	}
}
