import { IEvent } from '@studyportals/event-aggregation-service-interface';
import { EventType } from '../';

export class YoloNotDisplayedEvent implements IEvent {
	public static EventType: string = EventType.YOLO_NOT_DISPLAYED_EVENT;

	public readonly timestamp: Date;
	public readonly eventType = YoloNotDisplayedEvent.EventType;

	constructor() {
		this.timestamp = new Date();
	}
}
