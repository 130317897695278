import { Referrer } from '../../../interfaces';
import { GoogleClient } from '../../infrastructure/clients/google/google-client';
import { IStudentSignResult } from '../../infrastructure/clients/studentapi/interfaces/student-sign-result.interface';
import { StudentApiClient } from '../../infrastructure/clients/studentapi/student-api-client';

export class GoogleProvider {
	constructor(
		private googleClient: GoogleClient,
		private studentAPIClient: StudentApiClient,
	) {}

	public async initialize(): Promise<any> {
		return this.googleClient.initializeSDK();
	}

	public async login(payload: object = {}, idToken: string): Promise<IStudentSignResult> {
		return await this.studentAPIClient.signIn({ google: idToken }, payload);
	}

	public async showYolo(): Promise<string> {
		return this.googleClient.showYolo();
	}

	public renderGoogleButton(options: { element: any; template: string }, referrer: Referrer | null): void {
		this.googleClient.renderGoogleButton(options, referrer);
	}
}
