import { Referrer } from '../../../interfaces';
import { IIncentiveMutations, IIncentiveState } from '../../domain/dto/store.incentive.interfaces';

export class IncentiveMutations implements IIncentiveMutations {
	private readonly state: IIncentiveState;

	public constructor(state: IIncentiveState) {
		this.state = state;
	}

	public setReferrer(referrer: Referrer): void {
		this.state.referrer = referrer;
	}

	public setReferrerDirect(referrer: Referrer | null): void {
		this.state.referrerDirect = referrer;
	}
}
