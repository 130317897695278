import { IGlobalState, IGlobalStore } from '@/domain/dto/store.global.interfaces';

import { readonly } from 'vue';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const globalStore: IGlobalStore = {
	state: readonly(state) as IGlobalState,
	mutations,
	getters,
	actions,
};

export default globalStore;
