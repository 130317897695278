import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "popupContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.state.hidden)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}