import { IAuthenticationServiceApplication } from '../authentication-service.interface';
import { EventType } from '../';
import { IEvent } from '@studyportals/event-aggregation-service-interface';

export class AuthenticationServiceReadyEvent implements IEvent {
	public static EventType: string = EventType.AUTHENTICATION_SERVICE_READY_EVENT;

	public readonly timestamp: Date;
	public readonly eventType = AuthenticationServiceReadyEvent.EventType;

	constructor(public authenticationServiceApplication: IAuthenticationServiceApplication) {
		this.timestamp = new Date();
	}
}
