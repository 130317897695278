import { InternalEventType } from '../../../domain/enums/internal-event-type';
import globals from '@/utils/globals';
import { YoloNotDisplayedEvent } from 'interfaces';

export class YoloDisplayedEventHandler {
	public initiate(): void {
		this.yoloNotDisplayedEventListener();
	}

	public yoloNotDisplayedEventListener(): void {
		globals.eventBus.on(InternalEventType.YOLO_NOT_DISPLAYED, () => {
			window['EventAggregationService'].publishTo(YoloNotDisplayedEvent.EventType, new YoloNotDisplayedEvent());
		});
	}
}
