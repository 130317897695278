declare const STAGE: string;

export default {
	install: (app: any): void => {
		const rollbarConfig = {
			accessToken: '7737bca929cc4b8b951d0019e2893e87',
			captureUncaught: false,
			captureUnhandledRejections: false,
			checkIgnore: window['rollbarIgnore'],
			payload: {
				environment: STAGE,
			},
		};

		const rollbar =
			window && window['rollbar'] && window['rollbarIgnore'] ? new window['rollbar'](rollbarConfig) : null;

		app.config.errorHandler = (error: any, vm: any, info: any): void => {
			if (STAGE === 'prd') {
				rollbar.error(error);
			}

			throw error;
		};
	},
};
