import { CPIModalPlugin } from './../store/global/plugins/cpi-modal-plugin';
import { StudentAuthenticationService } from '../domain/services/student-authentication-service';
import Tracking from './tracking/tracking';
import mitt from 'mitt';
import { ISessionService } from '@studyportals/student-interfaces';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

const authenticationServiceTracking = new Tracking();
const studentAuthenticationService: StudentAuthenticationService = {} as StudentAuthenticationService;
const sessionService: ISessionService = {} as ISessionService;
const eventBus = mitt();
const eventAggregationService: IEventAggregationService = window['EventAggregationService'];

const cpiModalPlugin = new CPIModalPlugin(eventAggregationService);


export default {
	authenticationServiceTracking,
	studentAuthenticationService,
	sessionService,
	eventBus,
	eventAggregationService,
	cpiModalPlugin,
};
