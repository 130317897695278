import Actions from '../../../dictionary/tracking/actions';
import { AuthenticationProviderType } from '../../../domain/enums/authentication-provider-type';
import { PortalType } from '../../../../interfaces/enums/portal-type';
import { Referrer } from '../../../../interfaces/enums/referrer';
import { TrackingEvent } from './tracking-event';
import ISPAnalyticsEvent from '../../../domain/dto/spanalytics-event.interface';

export class SignInEvent extends TrackingEvent {
	protected action: string = Actions.SIGNIN;

	constructor(
		protected portal: PortalType | null,
		protected referrer: Referrer | null,
		protected provider: AuthenticationProviderType | null,
		protected additionalTags: string[] = [],
	) {
		super(portal, referrer, provider);
	}

	protected spanalyticsEvent(): ISPAnalyticsEvent {
		const tags = this.tags.concat(this.additionalTags);

		return {
			action: Actions.GENERAL,
			label: this.action,
			tags,
		};
	}
}
