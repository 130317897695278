import { AuthenticationCompletedEvent } from 'interfaces';
import { EventType } from '../enums/event-type';
import { IEventAggregationService } from '@studyportals/event-aggregation-service-interface';

export class EventBroadcastService {
	public static broadcast(type: EventType, data: any): void {
		document.dispatchEvent(
			new CustomEvent(type, {
				detail: data,
			}),
		);

		this.publishToEventAggregationService(type);
	}

	private static publishToEventAggregationService(type: EventType): void {
		const eventAggregationService: IEventAggregationService = (window as Window).EventAggregationService;

		switch (type) {
			case EventType.AUTHENTICATION_COMPLETED:
				eventAggregationService.publishTo(AuthenticationCompletedEvent.EventType, new AuthenticationCompletedEvent());
				break;
		}
	}
}
