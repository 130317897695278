import FormApp from '@/presentation/components/form.class';
import { FormType } from '../../../interfaces/enums/form-type';
import { defineComponent, onMounted, defineAsyncComponent, PropType } from 'vue';

export default defineComponent({
	components: {
		ConfirmForgotPassword: defineAsyncComponent(
			() => import('./FormSections/ConfirmForgotPassword/ConfirmForgotPassword.vue'),
		),
		ChangePasswordForm: defineAsyncComponent(() => import('./Form/ChangePasswordForm/ChangePasswordForm.vue')),
	},
	props: {
		form: {
			type: String as PropType<FormType>,
		},
	},
	setup: (props) => {
		const component = new FormApp(props.form as FormType);
		onMounted(component.mounted.bind(component));

		return {
			form: component.form,
			formTypes: component.formTypes,
		};
	},
});
