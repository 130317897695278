import { ActionState } from '../../../interfaces/enums/action-state';
import { IErrorMessage } from '../../domain/dto/error-message.interface';
import { AuthenticationProviderType } from '../../domain/enums/authentication-provider-type';
import { PortalType } from '../../../interfaces/enums/portal-type';
import IExperiment from '../../domain/dto/experiment.interface';
import { LayoutType } from '../../../interfaces/enums/layout-type';
import { IGlobalGetters } from '@/domain/dto/store.global.interfaces';
import globalStore from '.';
import { ICaptcha } from '@/domain/dto/captcha.interface';
import { Referrer } from '../../../interfaces';

const getters: IGlobalGetters = {
	actionState: (): ActionState => globalStore.state.actionState,
	layout: (): LayoutType => globalStore.state.layout,
	name: (): string | null => globalStore.state.name,
	email: (): string | null => globalStore.state.email,
	password: (): string | null => globalStore.state.password,
	oldPassword: (): string | null => globalStore.state.oldPassword,
	confirmPassword: (): string | null => globalStore.state.confirmPassword,
	errorMessage: (): IErrorMessage => globalStore.state.errorMessage,
	showSuccessMessage: (): boolean => globalStore.state.showSuccessMessage,
	directAuthTriggered: (): boolean => globalStore.state.directAuthTriggered,
	isValidPassword: (): boolean => globalStore.state.isValidPassword,
	portalType: (): PortalType | null => {
		if (globalStore.state.portalType) {
			return globalStore.state.portalType;
		}

		const portal = window.location.host;
		let portalType: PortalType | null = null;

		switch (true) {
			case portal.indexOf('mastersportal.com') !== -1 || portal.indexOf('mastersportal.fyi') !== -1:
				portalType = PortalType.MASTERS;
				break;
			case portal.indexOf('bachelorsportal.com') !== -1 || portal.indexOf('bachelorsportal.fyi') !== -1:
				portalType = PortalType.BACHELORS;
				break;
			case portal.indexOf('phdportal.com') !== -1 || portal.indexOf('phdportal.fyi') !== -1:
				portalType = PortalType.PHD;
				break;
			case portal.indexOf('distancelearningportal.com') !== -1 || portal.indexOf('distancelearningportal.fyi') !== -1:
				portalType = PortalType.DISTANCE_LEARNING;
				break;
			case portal.indexOf('shortcoursesportal.com') !== -1 || portal.indexOf('shortcoursesportal.fyi') !== -1:
				portalType = PortalType.SHORT_COURSES;
				break;
			case portal.indexOf('preparationcoursesportal.com') !== -1 ||
				portal.indexOf('preparationcoursesportal.fyi') !== -1:
				portalType = PortalType.PREPARATION_COURSES;
				break;
			case portal.indexOf('admissiontestportal.com') !== -1:
				portalType = PortalType.ADMISSION_TEST;
				break;
		}

		return portalType;
	},
	authProvider: (): AuthenticationProviderType | null => globalStore.state.authProvider,
	affiliate: (): string | null => globalStore.state.affiliate,
	trafficSource: (): string | null => globalStore.state.trafficSource,
	googleIdToken: (): string => globalStore.state.googleIdToken,
	facebookAccessToken: (): string | null => globalStore.state.facebookAccessToken,
	captcha: (): ICaptcha | null => globalStore.state.captcha,
	isPending: (authProvider: AuthenticationProviderType): boolean => {
		if (globalStore.state.pending.authProvider !== authProvider) {
			return false;
		}
		return globalStore.state.pending.isPending;
	},
	accessToken: (): string | null => globalStore.state.accessToken,
	resetPasswordEmailSend: (): boolean => globalStore.state.resetPasswordEmailSend,
	isValidName: (): boolean => {
		if (!globalStore.state.name || typeof globalStore.state.name === 'undefined') {
			return false;
		}
		return true;
	},
	isValidEmail: (): boolean => {
		if (!globalStore.state.email || typeof globalStore.state.email === 'undefined') {
			return false;
		}
		// eslint-disable-next-line no-useless-escape
		const $regex = /^([a-zA-Z0-9_\.\-\+%])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return $regex.test(globalStore.state.email);
	},
	runningExperiment: (): IExperiment | null => globalStore.state.runningExperiment,
	showEmailError: (): boolean => {
		if (globalStore.state.email !== null && !globalStore.getters.isValidEmail()) {
			return true;
		}
		if (globalStore.state.directAuthTriggered && !globalStore.getters.isValidEmail()) {
			return true;
		}
		return false;
	},
	showNameError: (): boolean => {
		if (globalStore.state.name !== null && !globalStore.getters.isValidName()) {
			return true;
		}
		if (globalStore.state.directAuthTriggered && !globalStore.getters?.isValidName()) {
			return true;
		}
		return false;
	},
	showPasswordError: (): boolean => {
		if (globalStore.state.password && !globalStore.getters.isValidPassword()) {
			return true;
		}
		if (globalStore.state.directAuthTriggered && !globalStore.getters.isValidPassword()) {
			return true;
		}
		return false;
	},
	userLoggedIn: (): boolean => globalStore.state.userLoggedIn ?? false,
	resetToken: (): string | null => globalStore.state.resetToken,
	referrerGoogleAuth: (): Referrer => {
		if (globalStore.state.referrerGoogleAuth === null) {
			throw new Error('referrerGoogleAuth has not been set');
		}

		return globalStore.state.referrerGoogleAuth;
	},
	referrerFacebookAuth: (): Referrer => {
		if (globalStore.state.referrerFacebookAuth === null) {
			throw new Error('referrerFacebookAuth has not been set');
		}

		return globalStore.state.referrerFacebookAuth;
	},
};

export default getters;
