export enum EventType {
	STUDENT_REGISTERED = 'student_registered',
	EXISTING_STUDENT_LOGGED_IN = 'logging_in_existing_user',
	STUDENT_LOGGED_IN = 'student_logged_in',
	OPT_IN_SUCCESS = 'opt_in_success',
	OPT_IN_FAIL = 'opt_in_fail',
	OPT_OUT = 'opt_out',
	AUTHENTICATION_COMPLETED = 'authentication_completed',
	AUTH_POPUP_DISMISSED = 'auth_popup_dismissed',
	YOLO_POPUP_DISMISSED = 'yolo_popup_dismissed',
	OPENID_PERMISSIONS_TRIGGERED = 'openId_persmissions_triggered',
}
