import { IIncentiveStore } from '../../domain/dto/store.incentive.interfaces';
import { createIncentiveStore } from '../../store/incentive';

export class IncentiveBase {
	private _incentiveStore: IIncentiveStore | null;

	protected get incentiveStore(): IIncentiveStore {
		if (this._incentiveStore === null) {
			this._incentiveStore = createIncentiveStore();
		}

		return this._incentiveStore;
	}

	public constructor() {
		this._incentiveStore = null;
	}
}
