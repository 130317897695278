import { defineComponent, onMounted } from 'vue';
import PopUp from '../PopUp/PopUp.vue';
import OptIn from './OptIn.class';
import { setupIncentive } from '../incentive-base';

export default defineComponent({
	components: {
		PopUp,
	},
	props: {
		appIdentifier: String,
	},
	setup: (props) => {
		const component = setupIncentive(new OptIn());
		onMounted(component.mounted.bind(component));

		return {
			notNow: component.notNow.bind(component),
			accept: component.accept.bind(component),
			trackImpression: component.trackImpression.bind(component),
			appIdentifier: props.appIdentifier,
		};
	},
});
