import globals from '@/utils/globals';
import {
	ITokenBasedSessionService,
	SessionCreatedEvent,
	SessionServiceReadyEvent,
} from '@studyportals/student-interfaces';
import globalStore from '..';
import { IEventAggregationService, ISubscriber } from '@studyportals/event-aggregation-service-interface';

export class LoginStatusCheckerPlugin implements ISubscriber<SessionServiceReadyEvent> {
	private get eventAggregationService(): IEventAggregationService | null {
		if (typeof window['EventAggregationService'] === 'undefined') {
			return null;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return window['EventAggregationService'];
	}

	public async notify(event: SessionServiceReadyEvent): Promise<void> {
		await this.onSessionServiceReady(event.sessionService);
	}

	public initiate(): void {
		if (!this.eventAggregationService) {
			return;
		}

		this.eventAggregationService.subscribeTo(
			SessionCreatedEvent.EventType,
			{
				notify: () => {
					globalStore.mutations.setLoggedInState(true);
				},
			},
			true,
		);

		this.eventAggregationService.subscribeTo(SessionServiceReadyEvent.EventType, this, true);
	}

	public async onSessionServiceReady(sessionService: ITokenBasedSessionService): Promise<void> {
		globals.sessionService = sessionService;

		const session = await sessionService.getSession();

		if (session !== null) {
			await globalStore.actions.setLoggedInState(true);
			return;
		}

		await globalStore.actions.setLoggedInState(false);
	}
}
