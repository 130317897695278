import { injectScriptToBody } from '@studyportals/microservice-injector';
import { InternalEventType } from '../../../domain/enums/internal-event-type';
import { Referrer } from '../../../../interfaces/enums/referrer';
import globalStore from '@/store/global';
import globals from '@/utils/globals';
import { GoogleLoginStatus, GSIClient, GSIDismissedReason, GSIId, GSINotification } from './types';

declare const google: GSIClient;
export class GoogleClient {
	constructor(private clientId: string) {}

	public async initializeSDK(): Promise<void> {
		await injectScriptToBody('https://accounts.google.com/gsi/client');
	}

	public async showYolo(): Promise<string> {
		await this.initializeSDK();

		return new Promise((resolve) => {
			this.initializeYolo(resolve);

			if (this.shouldSkipSigninPrompt) {
				return;
			}

			this.googleInstance.prompt((notification) => this.emitYoloStatus(notification));
		});
	}

	public renderGoogleButton(options: { element: Element; template: string }, referrer: Referrer | null): void {
		this.googleInstance.renderButton(options.element as HTMLElement, {
			type: options.template === 'Small' ? 'icon' : 'standard',
			shape: options.template === 'Small' ? 'square' : 'rectangular',
			text: 'signin_with',
			theme: 'filled_blue',
			size: 'large',
			width: this.getSiblingButtonWidth(options.element),
			logo_alignment: 'left',
			locale: 'en',
			click_listener: () => {
				globalStore.mutations.setReferrerGoogleAuth(referrer);
			},
		});
	}

	private emitYoloStatus(notification: GSINotification): void {
		const status: GoogleLoginStatus = GoogleClient.getGoogleLoginStatus(notification);

		switch (status) {
			case GoogleLoginStatus.SKIPPED:
				globals.eventBus.emit(InternalEventType.YOLO_NOT_DISPLAYED);
				break;
			case GoogleLoginStatus.LEGACY_DISPLAYED:
				globals.eventBus.emit(InternalEventType.YOLO_DISPLAYED);
				break;
			case GoogleLoginStatus.LOGGED_IN:
				globalStore.mutations.setReferrerGoogleAuth(Referrer.GOOGLE_YOLO);
				globals.eventBus.emit(InternalEventType.YOLO_DISMISSED);
				break;
		}
	}

	private getSiblingButtonWidth(element: Element): number {
		const siblingButton: Element | null = element.parentNode?.querySelector('.Provider') ?? null;

		if (!siblingButton) {
			return 400;
		}

		return Math.round(siblingButton.getBoundingClientRect().width);
	}

	private initializeYolo(callback: (credentials) => void, parentId?: string): void {
		this.googleInstance.initialize({
			client_id: this.clientId,
			callback: (response) => callback(response.credential),
			context: 'signin',
			ux_mode: 'popup',
			auto_select: false,
			prompt_parent_id: parentId,
			cancel_on_tap_outside: false,
			itp_support: true,
			use_fedcm_for_prompt: true,
		});

		globals.eventBus.emit(InternalEventType.YOLO_INITIALIZED);
	}

	private get googleInstance(): GSIId {
		return google.accounts.id;
	}

	private get shouldSkipSigninPrompt(): boolean {
		return !!window['OpenIdFlow'];
	}

	private static getGoogleLoginStatus(notification: GSINotification): GoogleLoginStatus {
		if (notification.isDisplayed()) {
			return GoogleLoginStatus.LEGACY_DISPLAYED;
		}

		if (notification.isSkippedMoment()) {
			return GoogleLoginStatus.SKIPPED;
		}

		if (notification.getDismissedReason() === GSIDismissedReason.CREDENTIAL_RETURNED) {
			return GoogleLoginStatus.LOGGED_IN;
		}

		return GoogleLoginStatus.SKIPPED;
	}
}
