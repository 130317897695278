export enum Referrer {
	HEADER = 'Header',
	EXCLUSIVE_ACCESS = 'ExclusiveAccessV2',
	EXCLUSIVE_ACCESS_HEADER = 'ExclusiveAccessV2Header',
	PERSONALITY_TEST = 'PersonalityTest',
	PERSONALITY_TEST_OLD = 'PersonalityTest_old',
	COUNTRY_TEST = 'CountryTest',
	COUNTRY_TEST_OLD = 'CountryTest_old',
	PERSONAL_UPDATES = 'PersonalUpdates',
	SP_SCHOLARSHIP = 'SpScholarship',
	PROFILE_QUESTIONNAIRES = 'ProfileQuestionnaires',
	PROFILE_QUESTIONNAIRES_POPUP = 'ProfileQuestionnairesPopup',
	WISHLIST = 'FavouriteStudy',
	REVIEWS = 'Reviews',
	REVIEWS_POPUP = 'ReviewsPopup',
	RECOMMENDATIONS = 'Recommendations',
	RECOMMENDATIONS_POPUP = 'RecommendationsPopup',
	GLOBAL_STUDY_AWARDS = 'GlobalStudyAwards',
	ACCOUNT = 'Account',
	OTHER = 'Other',
	COMPARISON_TOOL = 'ComparisonTool',
	EXPERIMENT = 'Experiment',
	GOOGLE_YOLO = 'GoogleYolo',
	ONBOARDING_QUESTIONNAIRE = 'OnboardingQuestionnaire',
	TEST_USER = 'test-user',
	FORM = 'form',
	FREE_TEST_MATERIAL = 'FreeTestMaterial',
	SCHOLARSHIP_TAB = 'ScholarshipTab',
	SCHOLARSHIP_TAB_POPUP = 'ScholarshipTabPopup',
	MY_JOURNEY = 'MyJourney',
	MY_JOURNEY_POPUP = 'MyJourneyPopup',
	MY_JOURNEY_SCHOLARSHIP = 'MyJourneyScholarship',
}
