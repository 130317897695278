import Labels from '../../dictionary/tracking/labels';
import { AuthenticationProviderType } from '@/domain/enums/authentication-provider-type';
import { EventType } from '@/domain/enums/event-type';
import { PopUpType } from '@/domain/enums/pop-up-type';
import { PortalType, LayoutType, ActionState, Referrer, FormType } from 'interfaces';
import { ProviderCustomActionType } from '@/domain/enums/provider-custom-action-type';
import { ErrorDisplayService } from '@/domain/services/error-display-service';
import { SignInFlow } from '@/infrastructure/clients/studentapi/enums/sign-in-flow';
import { IAuthenticateData } from '@/domain/dto/authenticate-data.interface';
import IEmailPreference from '../../domain/dto/email-preference.interface';
import IExperiment from '../../domain/dto/experiment.interface';
import { InternalEventType } from '@/domain/enums/internal-event-type';
import { IGlobalActions } from '@/domain/dto/store.global.interfaces';
import globalStore from '.';
import globals from '@/utils/globals';
import { ErrorMessageType } from '@/domain/enums/error-message-type';
import actionHelpers from './actionHelpers';
import { ICaptcha } from '@/domain/dto/captcha.interface';
import { watch } from 'vue';
import { StructEvent } from '../../utils/tracking/events/struct';
import Actions from '../../dictionary/tracking/actions';

const actions: IGlobalActions = {
	setActionState: (actionState: ActionState) => {
		if (!actionState) {
			actionState = ActionState.THIRD_PARTY_REGISTRATION;
		}

		globalStore.mutations.setActionState(actionState);
		if (actionState === ActionState.FORGOT_PASSWORD) {
			globalStore.mutations.resetPasswordEmailSend(false);
		}
		globalStore.actions.resetErrorMessage();
		globals.eventBus.emit('authPopupCalculatePadding');
	},

	setLayoutType: (layout: LayoutType) => {
		globalStore.mutations.setLayout(layout);
		if (layout === LayoutType.OPENID) {
			globals.eventBus.emit(InternalEventType.YOLO_DISMISSED);
		}
	},

	saveName: (name: string) => {
		globalStore.mutations.setName(name);
	},

	saveEmail: (email: string) => {
		globalStore.mutations.setEmail(email);
	},

	savePassword: (password: string) => {
		globalStore.mutations.setPassword(password);
	},

	saveOldPassword: (password: string) => {
		globalStore.mutations.setOldPassword(password);
	},

	saveConfirmPassword: (password: string) => {
		globalStore.mutations.setConfirmPassword(password);
	},

	savePortalType: (portalType: PortalType) => {
		globalStore.mutations.setPortalType(portalType);
	},

	saveAffiliate: (affiliate: string) => {
		globalStore.mutations.setAffiliate(affiliate);
	},

	saveTrafficSource: (source: string) => {
		globalStore.mutations.setTrafficSource(source);
	},

	saveCaptcha: (captchaToken: ICaptcha | null) => {
		globalStore.mutations.setCaptcha(captchaToken);
	},

	setPending: (isPending: boolean, authProvider: AuthenticationProviderType) => {
		globalStore.mutations.setPending(isPending, authProvider);
	},

	setRunningExperiment: (experiment: IExperiment) => {
		globalStore.mutations.setRunningExperiment(experiment);
	},

	acceptOptIn: async (preferences?: IEmailPreference) => {
		globals.eventBus.emit('closePopup', 'OptInPopUp');
		await globals.studentAuthenticationService.subscribeForEmails(preferences);

		actionHelpers.trackOptIn('Yes');

		document.dispatchEvent(new Event(EventType.OPT_IN_SUCCESS));
	},

	denyOptIn: () => {
		globals.eventBus.emit('closePopup', 'OptInPopUp');
		actionHelpers.trackOptIn('No');
		document.dispatchEvent(new Event(EventType.OPT_OUT));
	},

	dismissOptIn: () => {
		actionHelpers.trackOptIn('Dismiss');
		document.dispatchEvent(new Event(EventType.OPT_IN_FAIL));
		globals.cpiModalPlugin.renderCPIModal();
	},

	dismissAuthPopup: (referrer: Referrer | null) => {
		actionHelpers.trackAuthPopupDismiss(referrer);
		document.dispatchEvent(new Event(EventType.AUTH_POPUP_DISMISSED));
	},

	authenticate: async (data: IAuthenticateData, referrer: Referrer | null, showOptInOnRegister = true) => {
		try {
			actionHelpers.trackAction(Labels.AUTH_STARTED, referrer);
			globalStore.actions.resetErrorMessage();
			globalStore.actions.setPending(true, data.providerType);
			globalStore.mutations.setAuthProvider(data.providerType);
			const studentSignResult = await globals.studentAuthenticationService.authenticate(
				data.providerType,
				referrer,
				data.providerCustomAction,
			);

			const flow = studentSignResult.flow;
			const trackingTags = actionHelpers.getAdditionalTags(data);

			globals.eventBus.emit('closePopup', PopUpType.AUTH_POP_UP);

			if (flow === SignInFlow.REGISTERED) {
				globalStore.mutations.setAccessToken(studentSignResult.credentials.accessToken);
				actionHelpers.trackAction(Labels.OPTIN_STARTED, referrer);

				if (showOptInOnRegister) {
					globals.eventBus.emit('openPopup', {
						identifier: PopUpType.OPT_INT_POP_UP,
						referrer,
						appIdentifier: 'OPTIN',
					});
				}
				globalStore.actions.broadcastRegistration(data.providerType);
			}

			actionHelpers.trackAuthentication(flow, referrer, trackingTags);
			actionHelpers.trackAction(Labels.AUTH_COMPLETED, referrer);
			globalStore.actions.setPending(false, data.providerType);
		} catch (error) {
			globalStore.actions.catchAuthError(error as Error, data.providerType, referrer);
		}
	},

	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	register: async (referrer: Referrer | null, showOptInOnRegister = true) => {
		globalStore.mutations.directAuthTriggered();

		if (
			!globalStore.getters.isValidName() ||
			!globalStore.getters.isValidEmail() ||
			!globalStore.getters.isValidPassword()
		) {
			return;
		}

		const data: IAuthenticateData = {
			providerType: AuthenticationProviderType.DIRECT,
			providerCustomAction: ProviderCustomActionType.REGISTER,
		};
		await globalStore.actions.authenticate(data, referrer, showOptInOnRegister);
	},

	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	login: async (referrer: Referrer | null) => {
		globalStore.mutations.directAuthTriggered();
		if (!globalStore.getters.isValidEmail()) {
			return;
		}

		const data: IAuthenticateData = {
			providerType: AuthenticationProviderType.DIRECT,
			providerCustomAction: ProviderCustomActionType.LOGIN,
		};

		await globalStore.actions.authenticate(data, referrer);
	},

	startFacebookYoloAuthentication: async (facebookAccessToken: string | null) => {
		globalStore.mutations.setFacebookAccessToken(facebookAccessToken);
		const data: IAuthenticateData = {
			providerType: AuthenticationProviderType.FACEBOOK,
			providerCustomAction: ProviderCustomActionType.YOLO,
		};

		await globalStore.actions.authenticate(data, globalStore.getters.referrerFacebookAuth());

		globalStore.mutations.setFacebookAccessToken(null);
	},

	startGoogleYoloAuthentication: async (googleIdToken: string) => {
		globalStore.mutations.setGoogleIdToken(googleIdToken);

		const data: IAuthenticateData = {
			providerType: AuthenticationProviderType.GOOGLE,
			providerCustomAction: ProviderCustomActionType.YOLO,
		};

		await globalStore.actions.authenticate(data, globalStore.getters.referrerGoogleAuth());

		globalStore.mutations.setGoogleIdToken('');
	},

	resetErrorMessage: () => {
		globalStore.mutations.setErrorMessage(null, null);
	},

	setErrorMessage: (message: Error, type: ErrorMessageType | AuthenticationProviderType | null) => {
		const errorDisplayService: ErrorDisplayService = new ErrorDisplayService();
		const errorMessage: string = errorDisplayService.errorMessage(message);
		globalStore.mutations.setErrorMessage(errorMessage, type);
	},

	setPasswordValid: (valid: boolean) => {
		globalStore.mutations.setPasswordValid(valid);
	},

	forgotPassword: async (referrer: Referrer | null) => {
		if (!globalStore.getters.isValidEmail()) {
			return;
		}

		try {
			actionHelpers.trackAction(Labels.PASSWORD_RESET_STARTED, referrer);
			globalStore.actions.setPending(true, AuthenticationProviderType.DIRECT);

			await globals.studentAuthenticationService.forgotPassword();

			globalStore.actions.setPending(false, AuthenticationProviderType.DIRECT);
			globalStore.actions.resetPasswordEmailSend(true);
			actionHelpers.trackAction(Labels.PASSWORD_RESET_COMPLETED, referrer);
		} catch (error) {
			globalStore.actions.catchAuthError(error as Error, AuthenticationProviderType.DIRECT, referrer);
		}
	},

	confirmForgotPassword: async (referrer: Referrer | null) => {
		try {
			globalStore.actions.setPending(true, AuthenticationProviderType.DIRECT);
			globalStore.actions.savePasswordResetParams();

			await globals.studentAuthenticationService.confirmForgotPassword();
			await globalStore.actions.login(referrer);
			if (globalStore.getters.userLoggedIn()) {
				globalStore.actions.redirectToAccountPage();
				return;
			}

			watch(globalStore.getters.userLoggedIn, () => {
				globalStore.actions.redirectToAccountPage();
			});
		} catch (error) {
			globalStore.actions.catchAuthError(error as Error, AuthenticationProviderType.DIRECT, referrer);
		}
	},

	changePassword: async (referrer: Referrer | null) => {
		try {
			globalStore.actions.setPending(true, AuthenticationProviderType.DIRECT);

			await globals.studentAuthenticationService.changePassword();

			globalStore.actions.savePassword('');
			globalStore.actions.saveOldPassword('');
			globalStore.actions.saveConfirmPassword('');
			globalStore.actions.resetErrorMessage();

			globalStore.actions.setPending(false, AuthenticationProviderType.DIRECT);

			// show success message for 10 seconds
			globalStore.mutations.setShowSuccessMessage(true);
			setTimeout(() => {
				globalStore.mutations.setShowSuccessMessage(false);
			}, 10000);
		} catch (error) {
			globalStore.actions.catchAuthError(error as Error, AuthenticationProviderType.DIRECT, referrer);
		}
	},

	resetPasswordEmailSend: (send: boolean) => {
		globalStore.mutations.resetPasswordEmailSend(send);
	},

	setLoggedInState: async (loggedInState: boolean) => {
		globalStore.mutations.setLoggedInState(loggedInState);

		if (!loggedInState) {
			await globals.studentAuthenticationService.showYolo();
		}
	},

	renderGoogleButton: (options: { element: any; template: string }, referrer: Referrer | null) => {
		globals.studentAuthenticationService.renderGoogleButton(options, referrer);
	},

	broadcastRegistration: (provider: AuthenticationProviderType) => {
		document.dispatchEvent(
			new CustomEvent('student-registered', {
				detail: {
					provider,
				},
			}),
		);
	},

	showYolo: async () => {
		if (globalStore.getters.layout() !== LayoutType.OPENID) {
			await globals.studentAuthenticationService.showYolo();
		}
	},

	redirectToAccountPage: () => {
		window.location.href = '/account';
	},

	catchAuthError: (error: Error, provider: AuthenticationProviderType, referrer: Referrer | null) => {
		globals.authenticationServiceTracking.trackError(error, referrer);
		globalStore.actions.setPending(false, provider);
		globalStore.actions.setErrorMessage(error, provider);
		globals.eventBus.emit('resetCaptcha');
	},

	savePasswordResetParams: () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		const username = atob(urlParams.get('username') ?? '');
		const token = urlParams.get('resetToken') ?? '';

		globalStore.actions.saveEmail(username);
		globalStore.mutations.setResetToken(token);
	},

	trackImpression: (referrer: Referrer | FormType | null): void => {
		const portalType = globalStore.getters.portalType();

		globals.authenticationServiceTracking.track(
			new StructEvent(
				{
					action: Actions.INCENTIVE_IMPRESSION,
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					label: `${referrer}:${portalType}`,
				},
				referrer,
			),
		);
	},
};

export default actions;
