import { PortalType } from '../../../../interfaces/enums/portal-type';
import { Referrer } from '../../../../interfaces/enums/referrer';
import { AuthenticationProviderType } from '../../../domain/enums/authentication-provider-type';
import ITrackingObject from '../../../domain/dto/tracking-object.interface';
import ISPAnalyticsEvent from '../../../domain/dto/spanalytics-event.interface';
import Categories from '../../../dictionary/tracking/categories';
import Actions from '../../../dictionary/tracking/actions';
import { FormType } from '../../../../interfaces';

export class TrackingEvent {
	protected category: string = Categories.PROFILE;
	protected action: string = Actions.GENERAL;
	protected label?: string;
	protected property?: string;
	protected value?: number;

	constructor(
		protected portal: PortalType | null,
		protected referrer: Referrer | FormType | null,
		protected provider: AuthenticationProviderType | null,
	) {}

	protected get trackingEvent(): ITrackingObject {
		return {
			category: this.category,
			action: this.action,
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			label: `${this.provider}:${this.referrer}`,
		};
	}

	protected get tags(): string[] {
		const tags: string[] = [];

		if (this.provider !== null) {
			tags.push(this.provider);
		}
		if (this.portal !== null) {
			tags.push(this.portal);
		}
		if (this.referrer !== null) {
			tags.push(this.referrer);
		}

		return tags;
	}

	public get(trackingProvider: string): ITrackingObject | ISPAnalyticsEvent | null {
		switch (trackingProvider) {
			case 'redshift':
				return this.redshiftEvent();
			case 'ga':
				return this.gaEvent();
			case 'spanalytics':
				return this.spanalyticsEvent();
			default:
				return null;
		}
	}

	protected redshiftEvent(): ITrackingObject {
		return this.trackingEvent;
	}

	protected gaEvent(): ITrackingObject {
		return this.trackingEvent;
	}

	protected spanalyticsEvent(): ISPAnalyticsEvent {
		return {
			action: this.action,
			label: this.label,
			tags: this.tags,
		};
	}
}
