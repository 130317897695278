import { NoEmailError } from '@/infrastructure/clients/errors/no-email-error';
import { ValidationStudentApiError } from '@/infrastructure/clients/studentapi/errors/validation-student-api-error';
import { UnknownStudentApiError } from '@/infrastructure/clients/studentapi/errors/unknown-student-api-error';
import { UsernameExistsError } from '../errors/username-exists-error';
import { LinkedInUserCancelledLogin } from '@/infrastructure/clients/linkedin/errors/linkedin-user-cancelled-login';
import { LinkedInUserCancelledAuthorize } from '@/infrastructure/clients/linkedin/errors/linkedin-user-cancelled-authorize';
import { UnknownLinkedInError } from '@/infrastructure/clients/linkedin/errors/unknown-linkedin-error';
import { LinkedInPopupClosedError } from '@/infrastructure/clients/linkedin/errors/linkedin-popup-closed-error';
import { UnknownGoogleError } from '@/infrastructure/clients/google/errors/unknown-google-error';
import { GoogleProviderPopupClosedError } from '@/infrastructure/clients/google/errors/google-provider-popup-closed-error';
import { UnknownFacebookError } from '@/infrastructure/clients/facebook/errors/unknown-facebook-error';
import { ProviderPopupClosedError } from '@/infrastructure/clients/errors/provider-popup-closed-error';
import { NoEmailFacebookError } from '@/infrastructure/clients/facebook/errors/no-email-facebook-error';
import { UsernameOrPasswordIncorrectError } from '../errors/username-or-password-incorrect-error';
import { AttemptLimitExceededError } from '../errors/attempt-limit-exceeded-error';
import { IncorrectOldPasswordError } from '@/domain/errors/incorrect-old-password-error';

export class ErrorDisplayService {
	public errorMessage(error: Error): string {
		// Student Api Errors
		// { return "You have previously signed up via google. Please use google to access your account."; }
		if (error instanceof ValidationStudentApiError) {
			return 'Something went wrong.';
		}
		if (error instanceof UnknownStudentApiError) {
			return 'Something went wrong.';
		}
		if (error instanceof UsernameOrPasswordIncorrectError) {
			return 'The email and password combination you entered is incorrect.';
		}
		if (error instanceof IncorrectOldPasswordError) {
			return 'The old password you entered is incorrect.';
		}
		if (error instanceof UsernameExistsError) {
			return 'An account with this email address already exists. Please try logging in instead.';
		}
		if (error instanceof AttemptLimitExceededError) {
			return 'Your account has been temporarily disabled due to too many attempts. Please try again later.';
		}
		// Provider Errors
		// LinkedIn
		if (error instanceof LinkedInUserCancelledLogin) {
			return 'Something went wrong connecting to LinkedIn. Give it another try!';
		}
		if (error instanceof LinkedInUserCancelledAuthorize) {
			return 'Something went wrong connecting to LinkedIn. Give it another try!';
		}
		if (error instanceof UnknownLinkedInError) {
			return 'Something went wrong connecting to LinkedIn. Give it another try!';
		}
		if (error instanceof LinkedInPopupClosedError) {
			return 'Something went wrong connecting to LinkedIn. Give it another try!';
		}
		// Google
		if (error instanceof UnknownGoogleError) {
			return 'Something went wrong connecting to Google. Give it another try!';
		}
		if (error instanceof GoogleProviderPopupClosedError) {
			return 'Something went wrong connecting to Google. Give it another try!';
		}
		// Facebook
		if (error instanceof UnknownFacebookError) {
			return 'Something went wrong connecting to Facebook. Give it another try!';
		}
		if (error instanceof NoEmailFacebookError || error instanceof NoEmailError) {
			return 'Your Facebook account needs to have a verified email address';
		}
		// Overall Popup
		if (error instanceof ProviderPopupClosedError) {
			return 'Something went wrong.';
		}
		return 'Something went wrong.';
	}
}
