import { inject } from 'vue';
import { IncentiveBase } from './incentive-base.class';
import { IIncentiveStore } from '../../domain/dto/store.incentive.interfaces';
import { createIncentiveStore } from '../../store/incentive';

const setupIncentive = <TIncentive extends IncentiveBase>(incentive: TIncentive): TIncentive => {
	incentive['_incentiveStore'] = inject<IIncentiveStore>('incentiveStore', createIncentiveStore());
	return incentive;
};

export { setupIncentive };
