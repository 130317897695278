import { reactive } from 'vue';
import { IGlobalState } from '@/domain/dto/store.global.interfaces';
import { ActionState } from '../../../interfaces/enums/action-state';
import { LayoutType } from '../../../interfaces/enums/layout-type';

const state: IGlobalState = reactive({
	actionState: ActionState.THIRD_PARTY_REGISTRATION,
	layout: LayoutType.MAIN,
	name: null,
	email: null,
	password: null,
	oldPassword: null,
	confirmPassword: null,
	errorMessage: {
		message: '',
		type: null,
	},
	showSuccessMessage: false,
	directAuthTriggered: false,
	isValidPassword: false,
	portalType: null,
	authProvider: null,
	affiliate: null,
	trafficSource: null,
	pending: {
		isPending: false,
		authProvider: null,
	},
	resetPasswordEmailSend: false,
	accessToken: null,
	userLoggedIn: null,
	googleIdToken: '',
	facebookAccessToken: null,
	captcha: null,
	runningExperiment: null,
	resetToken: null,
	referrerGoogleAuth: null,
	referrerFacebookAuth: null,
});

export default state;
